import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":600},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{staticClass:"patient-new-profile-image-dialog",attrs:{"outlined":"","raised":"","elevation":"4","loading":_vm.isLoading}},[_c('template',{slot:"progress"},[_c(VProgressLinear,{attrs:{"color":"cyan","height":"10","indeterminate":""}})],1),_c(VCardTitle,{staticClass:"justify-space-between"},[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(!_vm.takenImage)?_c(VCardText,{staticClass:"d-flex justify-center"},[_c('video',{ref:"video",staticStyle:{"width":"100%"},on:{"canplay":_vm.initCanvas}}),_c('canvas',{ref:"canvas",staticStyle:{"display":"none","width":"640px","height":"640px"}})]):_c(VCardText,{staticClass:"d-flex justify-center"},[_c(VImg,{staticStyle:{"border":"1px solid #aaa"},attrs:{"contain":"","src":_vm.takenImage}})],1),(!_vm.takenImage && !_vm.isLoading)?_c(VCardText,{staticClass:"d-flex justify-center"},[_c(VBtn,{staticClass:"mx-2",attrs:{"dark":"","fab":"","large":"","color":"cyan darken-2"},on:{"click":function($event){return _vm.takePicture()}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("\n          mdi-camera\n        ")])],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }