






























import { Component } from "vue-property-decorator";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";

@Component({
})
export default class PatientTakeNewProfileImageDialog extends mixins(SmileMixin) {
  public video = null;
  public canvas = null;
  public takenImage = null;
  public isLoading = false;

  public closeDialog() {
    if (this.video.srcObject) {
      this.video.srcObject.getTracks()[0].stop();
    }
    this.$emit("close");
  }

  mounted() {
    this.video = this.$refs.video;
    this.canvas = this.$refs.canvas;
    this.isLoading = true;
    this.startCapture();
  }

  beforeDestroyed() {
    if (this.video.srcObject) {
      this.video.srcObject.getTracks().forEach(track => {
        track.stop();
      });
    }
  }

  public async startCapture() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      this.video.srcObject = stream;
      this.video.play();
      this.isLoading = false;
    } catch (err) {
      this.$toastr.e(this.$t("error_start_capture"));
      this.isLoading = false;
      this.closeDialog();
    }
  }

  public initCanvas() {
    this.canvas.width = this.video.videoWidth;
    this.canvas.height = this.video.videoHeight;
  }

  public takePicture() {
    const context = this.canvas.getContext("2d");
    context.drawImage(this.video, 0, 0);
    this.takenImage = this.canvas.toDataURL("image/jpeg");
    this.video.srcObject.getTracks()[0].stop();
    const file = this.dataURItoBlob(this.takenImage);
    this.$emit("onUploadNewImage", file);
  }

  public dataURItoBlob(dataURI: string) {
    let byteString: string;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    }
    else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

}
